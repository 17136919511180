import fasty_journey_begins from '../../animations/fasty_journey_begins.json'
import fasty_improve_health_story from '../../animations/fasty_improve_health_story.json'
import fasty_motivation_start from '../../animations/fasty_motivation_start.json'
import fasty_stats from '../../animations/fasty_stats.json'

const assets = {
    goal: {
        lose_weight: '/images/survey/goal_lose_weight.webp',
        boost_health: '/images/survey/goal_boost_health.svg',
        gain_muscles: '/images/survey/goal_gain_muscles.svg',
    },
    number_of_meals: {
        ramen_1: '/images/survey/ramen-1.svg',
        ramen_2: '/images/survey/ramen-2.svg',
        ramen_3: '/images/survey/ramen-3.svg',
        ramen_4: '/images/survey/ramen-4.svg',
    },
    home_cooked_meals: {
        pan_1: '/images/survey/home-cooked-food-1.svg',
        pan_2: '/images/survey/home-cooked-food-2.svg',
        pan_3: '/images/survey/home-cooked-food-3.svg',
        pan_4: '/images/survey/home-cooked-food-4.svg',
        pan_5: '/images/survey/home-cooked-food-5.svg',
    },
    diet: {
        no: '/images/survey/diet-no.svg',
        keto: '/images/survey/diet-keto.svg',
        vegan: '/images/survey/diet-vegan.svg',
        vegetarian: '/images/survey/diet-vegetarian.svg',
        pescatarian: '/images/survey/diet-pescatarian.svg',
        other: '/images/survey/diet-other.svg',
    },
    gender: {
        female: '/images/survey/gender_female.svg',
        male: '/images/survey/gender_male.svg',
    },
    trust_story: {
        tabitha: {
            metric: '/images/survey/tabitha-kg.webp',
            imperial: '/images/survey/tabitha-lb.webp',
        },
        henrica: {
            metric: '/images/survey/henrica-kg.webp',
            imperial: '/images/survey/henrica-lb.webp',
        },
        hilger: {
            metric: '/images/survey/hilger-kg.webp',
            imperial: '/images/survey/hilger-lb.webp',
        },
        johannes: {
            metric: '/images/survey/johannes-kg.webp',
            imperial: '/images/survey/johannes-lb.webp',
        },
    },
    social_proof: {
        group: {
            male: '/images/survey/social_male_group.webp',
            female: '/images/survey/social_female_group.webp',
        },
        reviews: '/images/survey/social_reviews.webp',
    },
    developed_by: {
        conny_wettcke: '/images/survey/conny_wettcke.webp',
        alice_fontecilla: '/images/survey/alice_fontecilla.webp',
        mario_ost: '/images/survey/mario_ost.webp',
        ilona_bürgel: '/images/survey/ilona_buergel.webp',
        reiner_wettcke: '/images/survey/reiner_wettcke.webp',
        janet_brill: '/images/survey/janet_brill.webp',
    },
    body: {
        female: {
            rounded: '/images/survey/body_female_rounded.svg',
            triangle: '/images/survey/body_female_triangle.svg',
            rectangle: '/images/survey/body_female_rectangle.svg',
            inverted_triangle: '/images/survey/body_female_inverted_triangle.svg',
            hourglass: '/images/survey/body_female_hourglass.svg',
        },
        male: {
            rounded: '/images/survey/body_male_rounded.svg',
            triangle: '/images/survey/body_male_triangle.svg',
            rectangle: '/images/survey/body_male_rectangle.svg',
            inverted_triangle: '/images/survey/body_male_inverted_triangle.svg',
            hourglass: '/images/survey/body_male_hourglass.svg',
        },
    },
    trust_national_center: `/images/survey/national_center_biotechnology.webp`,
    trust_rochester_medical: `/images/survey/university_of_rochester.webp`,
    story_1: '/images/survey/storytelling_1.svg',
    story_2: '/images/survey/storytelling_2.svg',
    story_3: '/images/survey/storytelling_3.svg',
    story_4: '/images/survey/storytelling_4.svg',
    story_5: '/images/survey/storytelling_5.svg',
    story_6: '/images/survey/storytelling_6.svg',
    story_7: '/images/survey/storytelling_7.svg',
    story_8: '/images/survey/storytelling_8.svg',
    story_9: '/images/survey/storytelling_9.svg',
    story_10: '/images/survey/storytelling_10.svg',
    reviews: {
        patricia_jones: `/images/survey/reviews_patricia_jones.webp`,
        maximilian_stritt: `/images/survey/reviews_maximilian_stritt.webp`,
        virginia_smith: `/images/survey/reviews_virginia_smith.webp`,
        edward_miller: `/images/survey/reviews_edward_miller.webp`,
        holly_robinson: `/images/survey/reviews_holly_robinson.webp`,
        jasmine_razee: `/images/survey/reviews_jasmine_razee.webp`,
    },
    fastic_download: {
        android: `/images/survey/appstore_android.webp`,
        ios: `/images/survey/appstore_ios.webp`,
        web: `/images/survey/appstore_web.webp`,
    },
    sign_in: {
        mocks: `/images/survey/sign-in-mock.webp`,
    },
    additional_step_1: {
        eating_cupcake: `/images/survey/eating_cupcake.webp`,
        eating_ramen: `/images/survey/eating_ramen.webp`,
        eating_spaghetti: `/images/survey/eating_spaghetti.webp`,
    },
    additional_step_2: {
        burger_and_chips: '/images/survey/burger_and_chips.svg',
        sandwich: '/images/survey/sandwich.svg',
        salad: '/images/survey/salad.svg',
        ramen: '/images/survey/ramen.svg',
        chef_hat: '/images/survey/chef_hat.svg',
        no_veg: '/images/survey/no_veg.svg',
    },
    prepare_plan: {
        workout_1: '/images/survey/workout_1.svg',
        workout_2: '/images/survey/workout_2.svg',
        workout_3: '/images/survey/workout_3.svg',
        workout_4: '/images/survey/workout_4.svg',
    },
    work: {
        v_active: '/images/survey/work-very-active.svg',
        sedentary: '/images/survey/work-sedentary.svg',
        active: '/images/survey/work-active.svg',
    },
    profile_placeholder: '/images/profile_placeholder.png',
    chevron_down: '/images/chevron_down.svg',
    animations: {
        fasty_journey_begins,
        fasty_improve_health_story,
        fasty_motivation_start,
        fasty_stats,
    },
}

export default assets
